import React, { useState, useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'

const PalletLoadedCF10492 = ({ role, shipment }) => {
    const [status, setStatus] = useState('start')
    const [loading, setLoading] = useState(false)
    const [loadType, setLoadType] = useState('')

    useEffect(() => {
        if (shipment?.customFields) {
            let loadTypeCF = shipment.customFields.find(cf => cf.fieldID === 10492)
            if (loadTypeCF && loadTypeCF.fieldValue !== loadType) {
                setLoadType(loadTypeCF.fieldValue)
            }
        }
    }, [shipment])

    const handleLoadTypeClick = (choice) => {
        if (loading || loadType === choice) return // Prevent redundant clicks

        setStatus('loading')
        setLoading(true)
        setLoadType(choice)

        updateLoadTypeViaShipmentV4(shipment.id, choice)
    }

    const updateLoadTypeViaShipmentV4 = async (webquotecontrol, choice) => {
        let url = `https://api.shippersedge.com/v4/shipment/` + webquotecontrol
        let body = {
            forceUpdate: true,
            customfieldValues: { field10492: choice }
        }

        try {
            let response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'x-api-Key': 'vdhy8wwjUJrwhxFpfuECTiAW'
                },
                body: JSON.stringify(body)
            })

            let data
            try {
                data = await response.json()
            } catch (err) {
                console.error('PalletLoadedCF10492 update ShipmentV4 error', err)
            }

            if (response.status === 200 && data) {
                setStatus('success')
            } else {
                setStatus('error')
            }
        } catch (error) {
            setStatus('error')
        } finally {
            setLoading(false)
        }
    }

    if (!role) return null

    return (
        <Fade cascade={false} duration={0}>
            <div id="PalletizedLoaded" className='card mt-3 d-flex flex-row position-relative' style={{ minHeight: '60px', visibility: shipment ? 'visible' : 'hidden' }}>
                {/* Overlay Spinner During Request */}
                {loading && (
                    <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-75" style={{ top: 0, left: 0, zIndex: 10 }}>
                        <div className="spinner-border text-primary" role="status" style={{ width: '2rem', height: '2rem' }}>
                        </div>
                    </div>
                )}
                <div id="PalletizedInfo" className="card-body d-flex flex-column justify-content-center" style={{ pointerEvents: loading ? 'none' : 'auto' }}>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="loadType"
                            id="Palletized"
                            checked={loadType === 'Palletized'}
                            disabled={loading}
                            onClick={() => handleLoadTypeClick('Palletized')}
                        />
                        <label className="form-check-label" htmlFor="Palletized">
                            Palletized {role === "carrier" ? <>Shipment</> : null}
                        </label>
                    </div>

                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="loadType"
                            id="Floor Loaded"
                            checked={loadType === 'Floor Loaded'}
                            disabled={loading}
                            onClick={() => handleLoadTypeClick('Floor Loaded')}
                        />
                        <label className="form-check-label" htmlFor="Floor Loaded">
                            Floor Loaded {role === "carrier" ? <>Shipment</> : null}
                        </label>
                    </div>
                </div>
            </div>
        </Fade>
    )
}

export default PalletLoadedCF10492